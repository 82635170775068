.active-card {
    background-color: rgb(255, 255, 255);
    transition: background-color 0.3s ease; /* Add transition effect for smooth color change */
}

.inactive-card {
    background-color: rgb(255, 255, 255);
    transition: background-color 0.3s ease; /* Add transition effect for smooth color change */
}

.active-card:hover {
    background-color: lightgreen; /* Change background color on hover */
}

.inactive-card:hover {
    background-color: lightcoral; /* Change background color on hover */
}
.media-container {
    width: 100%;
    height: 100%;
  }
  
  .instagram-media, .tiktok-embed {
    width: 100% !important;
    height: 100% !important;
  }